import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Nullable } from "primereact/ts-helpers";
import { useEffect, useState } from "react";
import { CContract, ContractsCreateResponse, ContractsService, ContractsUpdateResponse } from "src/apis/api-client";

export type ContractsEditProps = {
  mode: 'ADD' | 'MODIFY';
  data?: CContract;
  onCommit?: (data: CContract) => void;
}

export function ContractsEdit(props: ContractsEditProps) {

  const { data, mode, onCommit } = props;

  const [contract, setContract] = useState<CContract>();
  const [startDate, setStartDate] = useState<Nullable<Date>>(null);
  const [endDate, setEndDate] = useState<Nullable<Date>>(null);

  // Maps the initial data to the properties.
  useEffect(
    () => {
      if (!data) {
        setContract({});
      } else {
        setContract(data);
      }
    },
    [props, data]
  )

  /**
 * Creates the given CContract object in the database.
 * @param data Data to push towards the service.
 */
  async function create(data: CContract) {
    try {
      const response: ContractsCreateResponse = await ContractsService.create({ contract: data });

      if (onCommit && response.data)
        onCommit(response.data);

    } catch (error: unknown) {
      if (error instanceof Error) {
      }
    }
  }

  /**
   * Puts the modified CContract object into the database.
   * @param data Data to push towards the service.
   */
  async function modify(data: CContract) {
    try {
      if (data && data.id) {
        const response: ContractsUpdateResponse = await ContractsService.update({ contract: data });

        if (onCommit && response.data)
          onCommit(response.data);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
      }
    }
  }

  return (
    <div>
      {
        <div className="flex flex-wrap lg:flex-nowrap p-3 gap-2">
          <div className="flex w-full">
            <FileUpload multiple contentClassName="" style={{ width: "100%" }} />
          </div>
          <div className="flex p-5 flex-column gap-4 surface-0 lg:w-9 align-items-center">
            <div className="align-items-start flex flex-column gap-1 w-full">
              <InputText className="w-full border-none" placeholder="Title" />
              <InputText className="w-full border-none" placeholder="Contract number" />
            </div>
            <div className="align-items-start flex gap-1 w-full">
              <Button label="Save" icon="pi pi-save" outlined size="small" style={{ color: "#068D9D" }} />
              <Button label="Rescan" icon="pi pi-sync" outlined size="small" style={{ color: "#068D9D" }} />
              <Button label="Clear" icon="pi pi-eraser" outlined size="small" style={{ color: "#068D9D" }} />
            </div>
            <Divider align="left">
              <span>Details</span>
            </Divider>
            <div className="flex gap-8">
            <span className="p-float-label">
                <Calendar inputId="start_date" value={startDate} onChange={(e) => setStartDate(e.value)} />
                <label htmlFor="start_date">Start Date</label>
            </span>
            <span className="p-float-label">
                <Calendar inputId="end_date" value={endDate} onChange={(e) => setEndDate(e.value)} />
                <label htmlFor="end_date">End Date</label>
            </span>
            </div>
            <div className="flex gap-8">
              <InputText />
              <InputText />
            </div>
            <div className="flex gap-8">
              <InputText />
              <InputText />
            </div>
            <Divider align="left">
              <span>Pricing</span>
            </Divider>
            <div className="flex gap-8">
              <InputText />
              <InputText />
            </div>
            <div className="flex gap-8">
              <InputText />
              <InputText />
            </div>
            <div className="flex">
              <InputText className="flex" />
            </div>
            <div className="flex gap-4 pt-5">
              <Button label="CANCEL" outlined size="small" severity="danger" />
              <Button label="SAVE" size="small" />
              <Button label="SAVE AND CONTINUE" size="small" />
            </div>
          </div>
        </div>
      }
    </div>
  );
}
