import { CContract, ContractsService } from "src/apis/api-client";
import { Button } from "primereact/button";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { ContractCosts, ContractParty, ContractTags } from "../components";
import { DataTableStateEvent } from "primereact/datatable";
import { EfDataTable } from "src/shared";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { OverlayPanel } from "primereact/overlaypanel";

export function ContractsListView() {

  const [lazyParams, setLazyParams] = useState<DataTableStateEvent>();
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);
  const [infoText, setInfoText] = useState<string>();

  const navigate = useNavigate();

  const op = useRef<any | null>(null);

  const { isLoading, data, isError, refetch } = useQuery(
    ["ContractsService.get", lazyParams],
    async () => {
      const r = await ContractsService.get(
        first,
        rows
      );

      setTotalRecords(r.totalCount ?? 0);
      return r;
    },
    {
      refetchOnMount: true,
    }
  );

  /*
   * Handles the change of the current page in the paginator.
   */
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  /**
   * Handles displaying information text when triggered by a mouse event.
   *
   * @param e - The mouse event that triggered the display of information text.
   * @param infoText - The information text to be displayed.
   */
  const handleInfoText = (e: MouseEvent<HTMLElement>, infoText: string) => {
    setInfoText(infoText);
    op.current.toggle(e);
  }

  /* 
  Reload the contracts on state change. 
  */
  useEffect(
    () => {
      refetch();
    },
    [first, rows]
  );

  return (
    <div className="p-3">
      <EfDataTable
        value={data?.result && data.result.length > 0 ? data?.result : []}
        isLoading={false}
        skeleton
        lazyParams={lazyParams}
        onLazyParamsChanged={setLazyParams}
        totalRecords={totalRecords}
        onRowClick={(e) =>
          navigate("")
        }
        selectionMode="single"
        responsiveLayout="stack"
      >
        <Column
          header={<div className="flex align-items-center gap-2"><span>Description</span><i className="pi pi-info-circle" onClick={(e) => handleInfoText(e, "Description explanation")} /></div>}
          field="title"
          headerStyle={{ backgroundColor: "#53599A", color: "white" }}
        />
        <Column
          header={<div className="flex align-items-center gap-2"><span>Party</span><i className="pi pi-info-circle" onClick={(e) => handleInfoText(e, "Party explanation")} /></div>}
          body={
            (contract: CContract) =>
            (
              <div>
                {
                  contract.id && <ContractParty ContractId={contract.id} />
                }
              </div>
            )
          }
          headerStyle={{ backgroundColor: "#53599A", color: "white" }}
        />
        <Column
          header={<div className="flex align-items-center gap-2"><span>Cancelation Date</span><i className="pi pi-info-circle" onClick={(e) => handleInfoText(e, "Cancelation Date explanation")} /></div>}
          body={
            (contract: CContract) =>
            (
              <div>
                {
                  contract.startDate && new Date(contract.startDate).toLocaleDateString()
                }
              </div>
            )
          }
          headerStyle={{ backgroundColor: "#53599A", color: "white" }}
        />
        <Column
          header={<div className="flex align-items-center gap-2"><span>Cost/Period</span><i className="pi pi-info-circle" onClick={(e) => handleInfoText(e, "Cost/Period explanation")} /></div>}
          body={
            (contract: CContract) =>
            (
              <div>
                {
                  contract.id && <ContractCosts ContractId={contract.id} />
                }
              </div>
            )
          }
          headerStyle={{ backgroundColor: "#53599A", color: "white" }}
        />
        <Column
          header={<div className="flex align-items-center gap-2"><span>Tags</span><i className="pi pi-info-circle" onClick={(e) => handleInfoText(e, "Tags explanation")} /></div>}
          body={
            (contract: CContract) =>
            (
              <div>
                {
                  contract.id && <ContractTags ContractId={contract.id} />
                }
              </div>
            )
          }
          headerStyle={{ backgroundColor: "#53599A", color: "white" }} />
        <Column
          header={
            <div>
              <Button icon="pi pi-plus" outlined className="lg:text-white" onClick={() => navigate('add')} />
            </div>
          }
          headerStyle={{ backgroundColor: "#53599A", color: "white" }}
          body={
            <div className="flex gap-3">
              <Button
                className="p-button-text w-auto"
                iconPos="right"
                icon={"pi pi-pencil"}
              />
              <Button
                className="p-button-text w-auto p-button-danger"
                icon="pi pi-trash"
              />
            </div>
          }
        />
      </EfDataTable>
      <Paginator
        className="justify-content-end"
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
      <OverlayPanel ref={op}>
        <div>
          {infoText}
        </div>
      </OverlayPanel>
    </div>
  );
}
