import { Column } from "primereact/column";
import {
  DataTable,
  type DataTableStateEvent,
  type DataTableProps,
  DataTableValue,
} from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { Children, isValidElement, useEffect, useState } from "react";

export type EfDataTableProps<TData extends DataTableValue> = {
  lazyParams?: DataTableStateEvent;
  isLoading: boolean;
  skeleton: boolean;
  totalRecords: number;
  onLazyParamsChanged: (e: DataTableStateEvent) => unknown;
} & DataTableProps<TData[]>;

export function EfDataTable<TData extends DataTableValue>(
  props: EfDataTableProps<TData>
) {
  const {
    lazyParams,
    isLoading,
    skeleton,
    totalRecords,
    onLazyParamsChanged,
    children,
    ...dataTableProps
  } = props;

  const [columns, setColumns] = useState(Children.toArray(props.children));

  const [stateLazyParams, setStateLazyParams] = useState<
    Partial<DataTableStateEvent>
  >(
    props.lazyParams ?? {
      first: 0,
      rows: 10,
      page: 1,
      filters: {},
      multiSortMeta: undefined,
      sortField: "",
      sortOrder: undefined,
    }
  );

  useEffect(() => {
    if (lazyParams) {
      setStateLazyParams(lazyParams);
    }
  }, [lazyParams]);

  useEffect(() => {
    if (props.children) {
      setColumns(Children.toArray(props.children));
    }
  }, [props.children]);

  return (
    <>
      <DataTable
        lazy
        onFilter={onLazyParamsChanged}
        filters={stateLazyParams.filters}
        onSort={(e) => {
          e.first = 0;
          e.page = 0;
          onLazyParamsChanged(e);
        }}
        onPage={(e) => {
          onLazyParamsChanged(e);
        }}
        hidden={skeleton && isLoading}
        filterDisplay={"row"}
        filterDelay={350}
        currentPageReportTemplate={`Show {first} of {last} from ${totalRecords}`}
        emptyMessage="No entries found."
        {...dataTableProps}
      >
        {Children.map(
          children,
          (c, i) =>
            isValidElement(c) && (
              <Column
                key={i}
                filterPlaceholder="Search in table..."
                {...c.props}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                showFilterMenu={false}
                body={isLoading && skeleton ? <Skeleton /> : c.props.body}
              />
            )
        )}
      </DataTable>
    </>
  );
}

export default EfDataTable;
