/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractTagsCreateRequest } from '../models/ContractTagsCreateRequest';
import type { ContractTagsCreateResponse } from '../models/ContractTagsCreateResponse';
import type { ContractTagsDeleteResponse } from '../models/ContractTagsDeleteResponse';
import type { ContractTagsGetResponse } from '../models/ContractTagsGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractTagsService {

    /**
     * @param contractId 
     * @param skip 
     * @param take 
     * @returns ContractTagsGetResponse Success
     * @throws ApiError
     */
    public static get(
contractId?: string,
skip?: number,
take?: number,
): CancelablePromise<ContractTagsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ContractTags',
            query: {
                'contractId': contractId,
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ContractTagsCreateResponse Success
     * @throws ApiError
     */
    public static create(
requestBody?: ContractTagsCreateRequest,
): CancelablePromise<ContractTagsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ContractTags',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ContractTagsDeleteResponse Success
     * @throws ApiError
     */
    public static delete(
id: string,
): CancelablePromise<ContractTagsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/ContractTags/{id}',
            path: {
                'id': id,
            },
        });
    }

}
