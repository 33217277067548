import { EfSidebar } from 'src/shared/components/ef/ef-sidebar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UsersRouter } from 'src/domains';
import { ContractsRouter } from 'src/domains/contracts/_router';
import { AuthPageContainer } from 'src/shared';

export function CoreRouter() {
  return (
    <AuthPageContainer>
      <BrowserRouter>
        <div className="p-input-filled">
          <EfSidebar>
            <Routes>
              <Route path='/contracts/*' element={<ContractsRouter />}></Route>
              <Route path='/users/*' element={<UsersRouter />}></Route>
            </Routes>
          </EfSidebar>
        </div>
      </BrowserRouter>
    </AuthPageContainer>
  );
}

export default CoreRouter;
