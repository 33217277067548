/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractPaymentsCreateRequest } from '../models/ContractPaymentsCreateRequest';
import type { ContractPaymentsCreateResponse } from '../models/ContractPaymentsCreateResponse';
import type { ContractPaymentsDeleteResponse } from '../models/ContractPaymentsDeleteResponse';
import type { ContractPaymentsGetDetailResponse } from '../models/ContractPaymentsGetDetailResponse';
import type { ContractPaymentsGetResponse } from '../models/ContractPaymentsGetResponse';
import type { ContractPaymentsUpdateRequest } from '../models/ContractPaymentsUpdateRequest';
import type { ContractPaymentsUpdateResponse } from '../models/ContractPaymentsUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractPaymentsService {

    /**
     * @param contractId 
     * @param skip 
     * @param take 
     * @returns ContractPaymentsGetResponse Success
     * @throws ApiError
     */
    public static get(
contractId?: string,
skip?: number,
take?: number,
): CancelablePromise<ContractPaymentsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ContractPayments',
            query: {
                'contractId': contractId,
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ContractPaymentsCreateResponse Success
     * @throws ApiError
     */
    public static create(
requestBody?: ContractPaymentsCreateRequest,
): CancelablePromise<ContractPaymentsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ContractPayments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ContractPaymentsUpdateResponse Success
     * @throws ApiError
     */
    public static update(
requestBody?: ContractPaymentsUpdateRequest,
): CancelablePromise<ContractPaymentsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ContractPayments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ContractPaymentsGetDetailResponse Success
     * @throws ApiError
     */
    public static getDetail(
id: string,
): CancelablePromise<ContractPaymentsGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ContractPayments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns ContractPaymentsDeleteResponse Success
     * @throws ApiError
     */
    public static delete(
id: string,
): CancelablePromise<ContractPaymentsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/ContractPayments/{id}',
            path: {
                'id': id,
            },
        });
    }

}
