import {useMsal} from '@azure/msal-react';
import {NavigationItem} from 'src/core';
import {SignInSignOutButton} from 'src/shared';

export function TheNavigator() {
	const {accounts} = useMsal();

	return (
		<div className='flex flex-grow-1'>
			<div className='flex justify-content-start flex-grow-1'>
				<NavigationItem navigationUri='/home' icon='pi pi-home' label='Home' />
				<NavigationItem navigationUri='/foobars' icon='pi pi-question-circle' label='FooBars' needsAuthentication />
			</div>
			<div className='flex justify-content-end'>
				<NavigationItem label={accounts[0] ? accounts[0].name : ''} navigationUri='/users/user-info' needsAuthentication />
				<SignInSignOutButton loginType='redirect' />
			</div>
		</div>
	);
}

export default TheNavigator;
