import { Route, Routes } from "react-router-dom";
import { ContractsAddView, ContractsListView } from "../views";

export function ContractsRouter() {
	return (
		<Routes>
			<Route path={'/'} element={<ContractsListView />}></Route>
      <Route path={'/add'} element={<ContractsAddView />}></Route>
		</Routes>
	);
}

export default ContractsRouter;
