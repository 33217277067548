import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { PropsWithChildren, useRef } from 'react';
import logo from 'src/assets/images/kontrkt_logo_temp@4x.png';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export function EfSidebar(props: PropsWithChildren<any>) {

  const left = useRef<any | null>();

  const right = useRef<any | null>();

  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex relative lg:static surface-ground">
      <div id="app-sidebar-3" className="h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 border-gray-800 select-none" style={{ width: '280px', backgroundColor: "#068D9D" }}>
        <div className="flex flex-column h-full">
          <div className="flex align-items-center px-5 flex-shrink-0 pt-2" style={{ height: '60px' }}>
            <img src={logo} alt="kontrkt_logo" height={50} />
          </div>
          <div className="overflow-y-auto">
            <ul className="list-none p-3 m-0">
              <li>
                <span className="block p-3 font-medium text-white">Quickactions</span>
              </li>
              <li className='gap-3 flex justify-content-center'>
                <Button icon="pi pi-plus" outlined className='text-white' onClick={() => navigate('contracts/add')}/>
                <Button icon="pi pi-upload" outlined className='text-white' />
              </li>
            </ul>
            <ul className="list-none p-3 m-0">
              <li>
                <span className="block p-3 font-medium text-white">Overviews</span>
              </li>
              <li onClick={() => navigate('/contracts')}>
                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round hover:bg-gray-800 text-gray-300 hover:text-white transition-duration-150 transition-colors w-full">
                  <i className="pi pi-briefcase mr-2"></i>
                  <span>Contracts</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round hover:bg-gray-800 text-gray-300 hover:text-white transition-duration-150 transition-colors w-full">
                  <i className="pi pi-th-large mr-2"></i>
                  <span className="">Dashboard</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round hover:bg-gray-800 text-gray-300 hover:text-white transition-duration-150 transition-colors w-full">
                  <i className="pi pi-chart-bar mr-2"></i>
                  <span className="">Statistics</span>
                  <Ripple />
                </a>
              </li>
            </ul>
            <ul className="list-none p-3 m-0">
              <li>
                <span className="block p-3 font-medium text-white">Application</span>
              </li>
              <li>
                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round hover:bg-gray-800 text-gray-300 hover:text-white transition-duration-150 transition-colors w-full">
                  <i className="pi pi-cog mr-2"></i>
                  <span className="">Settings</span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="min-h-screen flex flex-column relative flex-auto">
        <div className="flex w-full justify-content-end align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static" style={{ height: '80px' }}>
          <div className="flex align-items-center">
            <StyleClass nodeRef={left} selector="#app-sidebar-3" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
              <a ref={left} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
                <i className="pi pi-bars text-4xl"></i>
                <Ripple />
              </a>
            </StyleClass>
            <span className="p-input-icon-left">
              <i className="pi pi-search"></i>
              <InputText placeholder="Search" />
            </span>
          </div>
          <div className='flex'>
            <StyleClass nodeRef={right} selector="@next" enterClassName="hidden" enterActiveClassName="fadein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
              <a ref={right} className="p-ripple cursor-pointer block lg:hidden text-700">
                <i className="pi pi-ellipsis-v text-2xl"></i>
                <Ripple />
              </a>
            </StyleClass>
            <ul className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
              <li>
                <a className="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full">
                  <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"><Badge severity="info" /></i>
                  <span className="block lg:hidden font-medium">Notifications</span>
                  <Ripple />
                </a>
              </li>
              <li className="border-top-1 surface-border lg:border-top-none">
                <a className="flex p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full">
                  <Avatar icon="pi pi-user" size="large" shape="circle" />
                  <Ripple />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          {props.children}
        </div>
      </div>
    </div>
  );
}
