import { ContractTagsGetResponse_CContractTag, ContractTagsService } from "src/apis/api-client";
import { Tag } from "primereact/tag";
import { useState } from "react";
import { useQuery } from "react-query";

export type ContractTagsProps = {
  ContractId: string;
}

export function ContractTags(props: ContractTagsProps) {

  const [contractTags, setContractTags] = useState<
    ContractTagsGetResponse_CContractTag[]
  >([]);

  const { isLoading, refetch } = useQuery(
    ["ContractTagsService.get", props.ContractId],
    async ({ queryKey }) => {
      const r = await ContractTagsService.get(queryKey[1] as string, 0, 10);

      if (r?.result) {
        setContractTags(r.result);
      }
      return r;
    },
    {
      staleTime: 100,
      refetchOnMount: "always",
    }
  );

  return (
    <div className="flex gap-2">
      {
        contractTags.map((e) => (
          <Tag style={{backgroundColor: "#6D9DC5"}} rounded>{e.tag?.value}</Tag>
        ))
      }
    </div>
  );
}
