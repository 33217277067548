import {type PropsWithChildren} from 'react';

export type PageContainerProps = {
	title?: string;
};

export function PageContainer(props: PropsWithChildren<PageContainerProps>) {
	const {title, children} = props;
	return (
		<div className='flex flex-column'>
			<div className='flex text-2xl'>{title}</div>
			<div className='flex-1'>{children}</div>
		</div>
	);
}
