/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractsCreateRequest } from '../models/ContractsCreateRequest';
import type { ContractsCreateResponse } from '../models/ContractsCreateResponse';
import type { ContractsDeleteResponse } from '../models/ContractsDeleteResponse';
import type { ContractsGetDetailResponse } from '../models/ContractsGetDetailResponse';
import type { ContractsGetResponse } from '../models/ContractsGetResponse';
import type { ContractsUpdateRequest } from '../models/ContractsUpdateRequest';
import type { ContractsUpdateResponse } from '../models/ContractsUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractsService {

    /**
     * @param skip 
     * @param take 
     * @returns ContractsGetResponse Success
     * @throws ApiError
     */
    public static get(
skip?: number,
take?: number,
): CancelablePromise<ContractsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Contracts',
            query: {
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ContractsCreateResponse Success
     * @throws ApiError
     */
    public static create(
requestBody?: ContractsCreateRequest,
): CancelablePromise<ContractsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Contracts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ContractsUpdateResponse Success
     * @throws ApiError
     */
    public static update(
requestBody?: ContractsUpdateRequest,
): CancelablePromise<ContractsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Contracts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ContractsGetDetailResponse Success
     * @throws ApiError
     */
    public static getDetail(
id: string,
): CancelablePromise<ContractsGetDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Contracts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns ContractsDeleteResponse Success
     * @throws ApiError
     */
    public static delete(
id: string,
): CancelablePromise<ContractsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Contracts/{id}',
            path: {
                'id': id,
            },
        });
    }

}
