import { ContractorType, ContractorsGetResponse_CContractor, ContractorsService } from "src/apis/api-client";
import { useState } from "react";
import { useQuery } from "react-query";

export type ContractPartyProps = {
  ContractId: string;
}

export function ContractParty(props: ContractPartyProps) {

  const [contractParty, setContractTags] = useState<
    ContractorsGetResponse_CContractor[]
  >([]);

  const { isLoading, refetch } = useQuery(
    ["ContractorsService.get", props.ContractId],
    async ({ queryKey }) => {
      const r = await ContractorsService.get(queryKey[1] as string, 0, 10);

      if (r?.result) {
        setContractTags(r.result);
      }
      return r;
    },
    {
      staleTime: 100,
      refetchOnMount: "always",
    }
  );

  return (
    <div className="flex gap-2">
      <div>
        {
          contractParty.map((e) => (
            e.contractorType === ContractorType.OWNER && <div>{e.legalEntity?.name}</div>
          ))
        }
      </div>
    </div>
  );
}
