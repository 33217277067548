import { CContractPayment, ContractPaymentsService } from "src/apis/api-client";
import { useState } from "react";
import { useQuery } from "react-query";

export type ContractCostsProps = {
  ContractId: string;
}

export function ContractCosts(props: ContractCostsProps) {
  const [contractParty, setContractTags] = useState<
  CContractPayment[]
>([]);

const { isLoading, refetch } = useQuery(
  ["ContractPaymentsService.get", props.ContractId],
  async ({ queryKey }) => {
    const r = await ContractPaymentsService.get(queryKey[1] as string, 0, 10);

    if (r?.result) {
      setContractTags(r.result);
    }
    return r;
  },
  {
    staleTime: 100,
    refetchOnMount: "always",
  }
);

return (
  <div className="flex gap-2">
    <div>
      {
        contractParty.map((e) => (
          <div>{e.grossAmount?.toFixed(2)}€/{e.paymentType}</div>
        ))
      }
    </div>
  </div>
);
}
