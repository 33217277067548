/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractorsCreateRequest } from '../models/ContractorsCreateRequest';
import type { ContractorsCreateResponse } from '../models/ContractorsCreateResponse';
import type { ContractorsDeleteResponse } from '../models/ContractorsDeleteResponse';
import type { ContractorsGetResponse } from '../models/ContractorsGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractorsService {

    /**
     * @param contractId 
     * @param skip 
     * @param take 
     * @returns ContractorsGetResponse Success
     * @throws ApiError
     */
    public static get(
contractId?: string,
skip?: number,
take?: number,
): CancelablePromise<ContractorsGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Contractors',
            query: {
                'contractId': contractId,
                'skip': skip,
                'take': take,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ContractorsCreateResponse Success
     * @throws ApiError
     */
    public static create(
requestBody?: ContractorsCreateRequest,
): CancelablePromise<ContractorsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Contractors',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ContractorsDeleteResponse Success
     * @throws ApiError
     */
    public static delete(
id: string,
): CancelablePromise<ContractorsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Contractors/{id}',
            path: {
                'id': id,
            },
        });
    }

}
